.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* HTML: <div class="loader"></div> */

.loader {
    width: 65px;
    height: 117px;
    position: relative;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    inset: 0;
    background: #ff8001;
    box-shadow: 0 0 0 50px;
    clip-path: polygon(100% 0, 23% 46%, 46% 44%, 15% 69%, 38% 67%, 0 100%, 76% 57%, 53% 58%, 88% 33%, 60% 37%);
    ;
}

.loader:after {
    animation: l8 1s infinite;
    transform: perspective(300px) translateZ(0px)
}

@keyframes l8 {
    to {
        transform: perspective(300px) translateZ(180px);
        opacity: 0
    }
}